<template>
  <b-card class="hp-card-4 hp-chart-text-color">
    <h4>Analytic</h4>
    <div id="revenue-column-card" class="overflow-hidden">
      <apexchart
        type="bar"
        height="303"
        legend="legend"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
  </b-card>
</template>

<script>
import axios from "axios";
import { BRow, BCol, BCard } from "bootstrap-vue";

export default {
  data() {
    return {
      series: [], 
      options: {
        chart: {
          id: "revenue-column-card",
          fontFamily: "Manrope, sans-serif",
          type: "bar",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        labels: {
          style: {
            fontSize: "14px",
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          borderColor: "#DFE6E9",
          row: {
            opacity: 0.5,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 2,
            columnWidth: "45%",
            endingShape: "rounded",
          },
        },
        stroke: {
          show: true,
          width: 4,
          colors: ["transparent"],
        },
        xaxis: {
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          tickPlacement: "between",
          labels: {
            style: {
              colors: ["636E72"],
              fontSize: "14px",
            },
          },
          categories: [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
          ],
        },
        legend: {
          horizontalAlign: "right",
          offsetX: 40,
          position: "top",
          markers: {
            radius: 12,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: ["636E72"],
              fontSize: "14px",
            },
            formatter: (value) => {
              return value; 
            },
          },
          min: 0,
          max: 100, 
          tickAmount: 5, 
        },
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
  },
  mounted() {
    this.fetchAnalysisData(); // Fetch data on mount
  },
  methods: {
    async fetchAnalysisData() {
      try {
        // Fetch monthly vehicle analysis
        const vehicleAnalysisResponse = await axios.get("/getMonthlyStolenVehicleAnalysis");
        const vehicleData = vehicleAnalysisResponse.data.map(item => item.monthly_vehicle_count);

        // Fetch monthly user analysis
        const userAnalysisResponse = await axios.get("/getMonthlyRecoverVehicleAnalysis");
        const userData = userAnalysisResponse.data.map(item => item.monthly_vehicle_count);
        this.series = [
          {
            name: "Stolen Vehicle",
            data: vehicleData, 
          },
          {
            name: "Recover Vehicle",
            data: userData, 
          },
        ];
      } catch (error) {
        console.error("Error fetching analysis data:", error);
      }
    },
  },
};
</script>
